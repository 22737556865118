<template>
    <div style="overflow-x: hidden" v-if="act">
        <b-tabs pills card>
            <b-tab title="Сырьё" active>
                <h3 class="text-center header-h">
                    {{ act.contractor.name }}
                    <span v-if="contractorCommentExists">({{ commentWithoutContractor }})</span>
                </h3>

                <h5 class="text-center header-h">
                    Заказ №{{ act.incoming_number }}<br/>на {{ formattedIncomingDate }}
                    <span v-if="act.canceled" class="text-danger" style="font-weight: bold;"><br/>Отменен</span>
                </h5>

                <h4 v-if="act.is_order_of_brand_kitchen" class="text-center header-h">
                    <b-badge variant="warning">
                        Заказ бренд кухня
                    </b-badge>
                </h4>

                <h6 class="text-center header-h" v-if="act.admissionActs.length">
                    <template v-for="admissionAct in act.admissionActs">
                        <router-link :to="{ name: 'AdmissionActEdit', params: { id: admissionAct.id } }" style="display: block; margin-bottom: 3px;">
                            Акт приема сырья №{{ admissionAct.id }}
                        </router-link>
                    </template>
                </h6>

                <table class="table table-striped">
                    <tbody>
                        <tr v-for="item in act.orderToContractorItems">
                            <td>{{ item.nomenclature.name }}</td>
                            <td class="text-right">
                                {{ parseFloat( item.count ).toFixed( 3 ) }} {{ item.nomenclature.measureUnit.name }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </b-tab>
            <b-tab :title="(act.comment ? '⭐ ' : '') + 'Комментарий'" v-if="act">
                <textarea disabled class="form-control commentary" v-model="act.comment"></textarea>
            </b-tab>
        </b-tabs>

        <control-panel>
            <b-button variant="danger" @click="$router.go(-1)">Выйти</b-button>

            <router-link
                style="color:#fff;"
                class="btn btn-success"
                v-if="!act.canceled && !isEnough"
                :to="{
                    name: 'AdmissionActCreate',
                    params: {
                        order_to_contractor_act_ids: [id],
                        contractor_id: act.contractor.id
                    }
                }"
            >Принять</router-link>

            <router-link
                v-if="isNotEnough"
                style="color:#fff;"
                class="btn btn-info"
                :to="{ name: 'OrderToContractorActDiffReport', params: { id: id } }"
            >Отчет о расхождениях с заказом</router-link>
        </control-panel>

        <users-of-the-act :act="act"></users-of-the-act>
    </div>
</template>

<script>
    import moment from 'moment';
    import ControlPanel from '../_common/ControlPanel';
    import UsersOfTheAct from '../_common/UsersOfTheAct';
    import { ORDER_TO_CONTRACTOR_ACTS_ENDPOINT } from '@utils/endpoints';
    import {STATUS_NOT_ENOUGH, STATUS_ENOUGH} from "./OrderToContractorActStatuses";

    const UNDEFINED_CONTRACTOR = 'Undefined'

    export default {
        name: "OrderToContractorActForm",
        components: { ControlPanel, UsersOfTheAct },
        props: {
            id: {}
        },
        data() {
            return {
                act: null,
            };
        },
        computed: {
            contractorCommentExists() {
                let actAndCommentExists = this.act.admissionAct && this.act.admissionAct.comment;
                if ( !actAndCommentExists ) {
                    return false;
                }

                let contractorIsUndefined = this.act.contractor.name === UNDEFINED_CONTRACTOR;
                if ( !contractorIsUndefined ) {
                    return false;
                }

                return this.act.admissionAct.comment.indexOf( 'Контрагент: ' ) + 1;
            },
            commentWithoutContractor() {
                return this.act.admissionAct.comment.replace( 'Контрагент: ', '' );
            },
            formattedIncomingDate() {
                return moment( this.act.incoming_date ).format( 'DD.MM.YYYY' );
            },
            isNotEnough() {
                return this.act.status === STATUS_NOT_ENOUGH;
            },
            isEnough() {
                return this.act.status === STATUS_ENOUGH;
            },
            preparedParams() {
                return {
                    with: [
                        'contractor',
                        'orderToContractorItems.nomenclature.measureUnit',
                        'admissionActs',
                        'admissionAct',
                    ],
                    appends: [
                        'status',
                    ],
                }
            },
        },
        methods: {
            getAct() {
                this.$http
                    .get( ORDER_TO_CONTRACTOR_ACTS_ENDPOINT + '/' + this.id, {
                        params: this.preparedParams
                    } )
                    .then( response => {
                        this.act = response.data.data;
                    } );
            },
        },
        mounted() {
            this.getAct();
        }
    }
</script>

<style scoped lang="sass">
    .header-h
        margin: 0
        padding: 10px

    .commentary
        width: 100%
        min-width: 100%
        min-height: 200px
</style>
